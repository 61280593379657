import React from 'react';

const SvgExpire = props => (
    <svg width="1em" height="1em" viewBox="0 0 60 60" {...props}>
        <text
            fill="#000"
            fillRule="evenodd"
            fontFamily="AppleColorEmoji, Apple Color Emoji"
            fontSize={50}
        >
            <tspan x={5} y={50}>
                {'\uD83D\uDE80'}
            </tspan>
        </text>
    </svg>
);

export default SvgExpire;
