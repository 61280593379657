import React from 'react';

const SvgBranding = props => (
    <svg width="1em" height="1em" viewBox="0 0 60 60" {...props}>
        <defs>
            <linearGradient x1="35.976%" y1="0%" x2="100%" y2="100%" id="Branding_svg__a">
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#DCDCDC" offset="100%" />
            </linearGradient>
            <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="Branding_svg__b">
                <stop stopColor="#3023AE" offset="0%" />
                <stop stopColor="#B96DD7" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <rect
                fill="url(#Branding_svg__a)"
                fillRule="nonzero"
                x={3}
                y={42}
                width={54}
                height={18}
                rx={4}
            />
            <path
                d="M5.306 43.552l31.886-31.887a4 4 0 015.657 0l8.48 8.48a4 4 0 01-.006 5.663L21.106 55.903a4 4 0 01-5.024.506L5.933 49.72a4 4 0 01-.627-6.168z"
                fill="#C8C8C8"
                fillRule="nonzero"
                opacity={0.5}
            />
            <rect
                fill="#FFF"
                fillRule="nonzero"
                transform="rotate(-45 28 32)"
                x={1}
                y={22}
                width={54}
                height={20}
                rx={4}
            />
            <g transform="rotate(-45 52.97 -3.314)">
                <rect fill="#4335B5" x={24} width={8} height={8} rx={2} />
                <rect fill="#9A61CB" x={12} width={8} height={8} rx={2} />
                <rect fill="#B069D5" width={8} height={8} rx={2} />
            </g>
            <rect
                fill="url(#Branding_svg__b)"
                fillRule="nonzero"
                x={3}
                y={6}
                width={20}
                height={54}
                rx={4}
            />
            <path
                d="M9.616 41.5v-1.604h5.492v-3.475h1.276V41.5H9.616zm6.884-9.218c0 .72-.151 1.368-.454 1.946a3.42 3.42 0 01-1.252 1.356c-.532.327-1.13.49-1.794.49a3.357 3.357 0 01-1.794-.49 3.42 3.42 0 01-1.252-1.356c-.303-.578-.454-1.226-.454-1.946s.151-1.366.454-1.94a3.465 3.465 0 011.252-1.357A3.332 3.332 0 0113 28.49c.664 0 1.262.165 1.794.495.531.33.949.782 1.252 1.357.303.574.454 1.22.454 1.94zm-1.334 0c0-.41-.092-.779-.276-1.109-.184-.33-.44-.589-.768-.777A2.217 2.217 0 0013 30.114c-.419 0-.793.094-1.122.282a2.001 2.001 0 00-.768.777c-.184.33-.276.7-.276 1.11 0 .408.092.778.276 1.108.184.33.44.59.768.777.33.188.703.282 1.122.282.419 0 .793-.094 1.122-.282.328-.188.584-.447.768-.777.184-.33.276-.7.276-1.109zM12.894 22.6v-1.465h2.746c.277.376.49.812.638 1.307.148.495.222.993.222 1.495 0 .72-.15 1.366-.45 1.94a3.395 3.395 0 01-1.247 1.352 3.375 3.375 0 01-1.803.49c-.67 0-1.271-.164-1.803-.49a3.379 3.379 0 01-1.247-1.362c-.3-.58-.45-1.234-.45-1.96 0-.607.1-1.158.3-1.654.2-.495.49-.91.87-1.247l.928 1.03a2.408 2.408 0 00-.764 1.792c0 .442.09.835.271 1.178.18.343.435.61.764.802.329.191.706.287 1.131.287.419 0 .793-.096 1.122-.287.328-.191.584-.457.768-.797.184-.34.276-.728.276-1.163a2.65 2.65 0 00-.29-1.248h-1.982zm3.606-6.307c0 .72-.151 1.368-.454 1.946a3.42 3.42 0 01-1.252 1.356c-.532.327-1.13.49-1.794.49a3.357 3.357 0 01-1.794-.49 3.42 3.42 0 01-1.252-1.356c-.303-.578-.454-1.226-.454-1.946s.151-1.366.454-1.94a3.465 3.465 0 011.252-1.357A3.332 3.332 0 0113 12.5c.664 0 1.262.165 1.794.495.531.33.949.782 1.252 1.356.303.575.454 1.222.454 1.941zm-1.334 0c0-.41-.092-.779-.276-1.109-.184-.33-.44-.589-.768-.777A2.217 2.217 0 0013 14.124c-.419 0-.793.094-1.122.282a2.001 2.001 0 00-.768.777c-.184.33-.276.7-.276 1.11 0 .408.092.778.276 1.108.184.33.44.59.768.777.33.188.703.282 1.122.282.419 0 .793-.094 1.122-.282.328-.188.584-.447.768-.777.184-.33.276-.7.276-1.109z"
                fill="#FFF"
                fillRule="nonzero"
            />
            <circle fill="#FFF" cx={13} cy={50} r={3} />
        </g>
    </svg>
);

export default SvgBranding;
