import React from 'react';

const SvgCustom = props => (
    <svg width="1em" height="1em" viewBox="0 0 60 60" {...props}>
        <defs>
            <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="Custom_svg__a">
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#DCDCDC" offset="100%" />
            </linearGradient>
            <linearGradient x1="0%" y1="100%" x2="80.957%" y2="19.043%" id="Custom_svg__b">
                <stop stopColor="#0034F2" offset="0%" />
                <stop stopColor="#00BAFF" offset="100%" />
            </linearGradient>
            <rect id="Custom_svg__c" x={16} y={16} width={26} height={39} rx={2} />
        </defs>
        <g transform="translate(8 2)" fill="none" fillRule="evenodd">
            <rect fill="url(#Custom_svg__a)" fillRule="nonzero" width={26} height={39} rx={2} />
            <use
                fill="url(#Custom_svg__b)"
                style={{
                    mixBlendMode: 'multiply'
                }}
                xlinkHref="#Custom_svg__c"
            />
            <rect stroke="#FFF" strokeWidth={3} x={14.5} y={14.5} width={29} height={42} rx={2} />
            <circle
                stroke="#FFF"
                strokeWidth={3}
                fill="#00BAFF"
                fillRule="nonzero"
                cx={42}
                cy={16}
                r={4.5}
            />
            <circle
                stroke="#FFF"
                strokeWidth={3}
                fill="#00BAFF"
                fillRule="nonzero"
                cx={42}
                cy={55}
                r={4.5}
            />
            <circle
                stroke="#FFF"
                strokeWidth={3}
                fill="#00BAFF"
                fillRule="nonzero"
                cx={16}
                cy={16}
                r={4.5}
            />
            <circle
                stroke="#FFF"
                strokeWidth={3}
                fill="#00BAFF"
                fillRule="nonzero"
                cx={16}
                cy={55}
                r={4.5}
            />
        </g>
    </svg>
);

export default SvgCustom;
