import React from 'react';

const SvgSoonCircle = props => (
    <svg width="1em" height="1em" viewBox="0 0 102 102" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M51 101c27.614 0 50-22.386 50-50S78.614 1 51 1 1 23.386 1 51s22.386 50 50 50z"
                stroke="#C8C8C8"
                fill="#FFF"
                strokeDasharray="10,10"
            />
            <path
                d="M49.429 49.429v-6.286h3.142v6.286h6.286v3.142h-6.286v6.286H49.43v-6.286h-6.286V49.43h6.286z"
                fill="#C8C8C8"
            />
        </g>
    </svg>
);

export default SvgSoonCircle;
