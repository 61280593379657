import React from 'react';

const SvgUnsplashCircle = props => (
    <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle fill="#A2E6FF" opacity={0.2} cx={50} cy={50} r={50} />
            <path
                d="M68 47.1V68H30V47.1h11.4v11.4h15.205V47.1H68zM56.6 30h-.047v9.5H41.4V30h15.2z"
                fill="#000"
            />
        </g>
    </svg>
);

export default SvgUnsplashCircle;
