import React from 'react';

const SvgDragCircle = props => (
    <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle fill="#A2E6FF" opacity={0.2} cx={50} cy={50} r={50} />
            <path
                d="M37.823 43.056H35V36h7.056v2.823h-4.233v4.233zm4.233 21.17H35v-7.057h2.823v4.234h4.233v2.823zm21.17-21.17h-2.823v-4.233H56.17V36h7.057v7.056zM46.29 36h5.645v2.823H46.29V36zm0 25.403h5.645v2.823H46.29v-2.823zM60.403 47.29h2.823v5.645h-2.823V47.29zM35 47.29h2.823v5.645H35V47.29zm35 12.42l-5.645 2.822L70 68.177 67.177 71l-5.645-5.645L58.71 71l-4.234-15.524L70 59.71z"
                fillRule="nonzero"
                fill="#00BAFF"
            />
        </g>
    </svg>
);

export default SvgDragCircle;
