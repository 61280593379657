import React from 'react';

const SvgPremium = props => (
    <svg width="1em" height="1em" viewBox="0 0 60 60" {...props}>
        <defs>
            <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="Premium_svg__a">
                <stop stopColor="#FFCB72" offset="0%" />
                <stop stopColor="#7E4E13" offset="100%" />
            </linearGradient>
        </defs>
        <path
            d="M54.687 11.262h-.001A5.234 5.234 0 0160 16.516c0 2.918-2.367 5.253-5.217 5.253-.242 0-.435 0-.628-.048l-1.981 19.555A3.002 3.002 0 0149.179 44H10.82a3.002 3.002 0 01-2.995-2.724L5.846 21.72c-.194.048-.435.048-.629.048C2.32 21.77 0 19.386 0 16.516c0-2.968 2.367-5.303 5.314-5.254 2.85.049 5.17 2.578 5.073 5.448-.049 1.557-.822 2.968-1.981 3.892 5.99 8.075 12.078 8.707 19.13-4.67-2.511-1.265-3.912-4.476-2.077-7.686.918-1.557 2.657-2.287 4.445-2.238h.193c1.787-.097 3.575.681 4.444 2.238 1.836 3.21.435 6.421-2.077 7.686 7.054 13.377 13.14 12.745 19.131 4.67-1.16-.924-1.932-2.286-1.98-3.892-.146-2.87 2.221-5.4 5.072-5.448zM11 47h38a2 2 0 012 2v2a2 2 0 01-2 2H11a2 2 0 01-2-2v-2a2 2 0 012-2z"
            fill="url(#Premium_svg__a)"
            fillRule="evenodd"
        />
    </svg>
);

export default SvgPremium;
