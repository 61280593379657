import React from 'react';

const SvgSettingsCircle = props => (
    <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle fill="#A2E6FF" opacity={0.2} cx={50} cy={50} r={50} />
            <path
                d="M41.25 48.75h-2.5v-12.5a1.25 1.25 0 012.5 0v12.5zm10-10h-2.5v-2.5a1.25 1.25 0 012.5 0v2.5zm10 10h-2.5v-12.5a1.25 1.25 0 012.5 0v12.5zM40 51.25c2.758 0 5 2.242 5 5 0 2.323-1.6 4.264-3.75 4.823v2.677a1.25 1.25 0 01-2.5 0v-2.677c-2.15-.56-3.75-2.5-3.75-4.823 0-2.758 2.242-5 5-5zm15-5c0 2.322-1.6 4.264-3.75 4.823V63.75a1.25 1.25 0 01-2.5 0V51.073c-2.15-.56-3.75-2.5-3.75-4.823 0-2.758 2.242-5 5-5s5 2.242 5 5zm10 10c0 2.323-1.6 4.264-3.75 4.823v2.677a1.25 1.25 0 01-2.5 0v-2.677c-2.15-.56-3.75-2.5-3.75-4.823 0-2.758 2.242-5 5-5s5 2.242 5 5z"
                fillRule="nonzero"
                fill="#00BAFF"
            />
        </g>
    </svg>
);

export default SvgSettingsCircle;
