import React from 'react';

const SvgDownloadCircle = props => (
    <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle fill="#A2E6FF" opacity={0.2} cx={50} cy={50} r={50} />
            <path
                d="M63.75 40c.691 0 1.25.559 1.25 1.25v22.5c0 .691-.559 1.25-1.25 1.25h-27.5c-.691 0-1.25-.559-1.25-1.25v-27.5c0-.691.559-1.25 1.25-1.25h10c.393 0 .764.185 1 .5l3.375 4.5H63.75zM55 50.732l-3.75 3.75V45h-2.5v9.482L45 50.732 43.232 52.5l5.884 5.883a1.249 1.249 0 001.768 0l5.883-5.883L55 50.732z"
                fillRule="nonzero"
                fill="#00BAFF"
            />
        </g>
    </svg>
);

export default SvgDownloadCircle;
