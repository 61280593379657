import React from 'react';

const SvgPlaceholderCircle = props => (
    <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle fill="#A2E6FF" opacity={0.2} cx={50} cy={50} r={50} />
            <path
                d="M63.75 55.786h-1.975l-2.25-11.572H60c.69 0 1.25-.575 1.25-1.285v-5.143c0-.71-.56-1.286-1.25-1.286h-5c-.69 0-1.25.576-1.25 1.286v1.285h-7.5v-1.285c0-.71-.56-1.286-1.25-1.286h-5c-.69 0-1.25.576-1.25 1.286v5.143c0 .71.56 1.285 1.25 1.285h.475l-2.25 11.572H36.25c-.69 0-1.25.575-1.25 1.285v5.143c0 .71.56 1.286 1.25 1.286h5c.69 0 1.25-.576 1.25-1.286V60.93h15v1.285c0 .71.56 1.286 1.25 1.286h5c.69 0 1.25-.576 1.25-1.286v-5.143c0-.71-.56-1.285-1.25-1.285zM42.5 58.357v-1.286c0-.71-.56-1.285-1.25-1.285h-.475l2.25-11.572H45c.69 0 1.25-.575 1.25-1.285v-1.286h7.5v1.286c0 .71.56 1.285 1.25 1.285h1.975l2.25 11.572h-.475c-.69 0-1.25.575-1.25 1.285v1.286h-15z"
                fill="#00BAFF"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default SvgPlaceholderCircle;
