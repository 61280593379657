import React from 'react';

const SvgFeatureList = props => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle fill="#A2E6FF" opacity={0.2} cx={10} cy={10} r={10} />
            <path
                d="M4.857 10.171l1.029-1.028 2.571 1.543 5.657-4.115L15.143 7.6l-6.686 6.686z"
                fill="#F60"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default SvgFeatureList;
