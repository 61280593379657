import React from 'react';

const SvgFeatureListNotAvailable = props => (
    <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle fill="#F2F2F2" cx={15} cy={15} r={15} />
            <path
                d="M19.035 10.965a.856.856 0 00-1.21 0l-2.82 2.82-2.819-2.82a.856.856 0 00-1.21 1.21l2.819 2.82-2.82 2.819a.856.856 0 101.211 1.21l2.82-2.819 2.819 2.82a.856.856 0 001.21-1.211l-2.82-2.82 2.82-2.819a.856.856 0 000-1.21z"
                fill="#FF001F"
            />
        </g>
    </svg>
);

export default SvgFeatureListNotAvailable;
