import React from 'react';
import PropTypes from 'prop-types';
import FeatureList from './Icons/FeatureList';
import FeatureListNotAvailable from './Icons/FeatureListNotAvailable';
import CanvaCircle from './Icons/CanvaCircle';
import Canva from './Icons/Canva';
import DropboxCircle from './Icons/DropboxCircle';
import Dropbox from './Icons/Dropbox';
import UnsplashCircle from './Icons/UnsplashCircle';
import SoonCircle from './Icons/SoonCircle';
import DownloadCircle from './Icons/DownloadCircle';
import PlaceholderCircle from './Icons/PlaceholderCircle';
import SettingsCircle from './Icons/SettingsCircle';
import DragCircle from './Icons/DragCircle';
import Branding from './Icons/Branding';
import CustomMockups from './Icons/Custom';
import Expire from './Icons/Expire';
import Logo from './Icons/Logo';
import Premium from './Icons/Premium';
import Video from './Icons/Video';

const MarketingIcon = props => {
    let width = 20;
    let height = 20;

    if (props.width) {
        width = props.width;
    }
    if (props.height) {
        height = props.height;
    } else {
        height = width;
    }
    const className = props.className ? props.className : '';

    switch (props.name) {
        case 'Branding':
            return <Branding width={width} height={height} className={className} {...props} />;
        case 'CanvaCircle':
            return <CanvaCircle width={width} height={height} className={className} {...props} />;
        case 'Canva':
            return <Canva width={width} height={height} className={className} {...props} />;
        case 'CustomMockups':
            return <CustomMockups width={width} height={height} className={className} {...props} />;
        case 'DownloadCircle':
            return (
                <DownloadCircle width={width} height={height} className={className} {...props} />
            );
        case 'DragCircle':
            return <DragCircle width={width} height={height} className={className} {...props} />;
        case 'DropboxCircle':
            return <DropboxCircle width={width} height={height} className={className} {...props} />;
        case 'Dropbox':
            return <Dropbox width={width} height={height} className={className} {...props} />;
        case 'Expire':
            return <Expire width={width} height={height} className={className} {...props} />;

        case 'FeatureList':
            return <FeatureList width={width} height={height} className={className} {...props} />;
        case 'FeatureListNotAvailable':
            return (
                <FeatureListNotAvailable
                    width={width}
                    height={height}
                    className={className}
                    {...props}
                />
            );
        case 'Logo':
            return <Logo width={width} height={height} className={className} {...props} />;
        case 'Premium':
            return <Premium width={width} height={height} className={className} {...props} />;
        case 'PlaceholderCircle':
            return (
                <PlaceholderCircle width={width} height={height} className={className} {...props} />
            );

        case 'SoonCircle':
            return <SoonCircle width={width} height={height} className={className} {...props} />;

        case 'SettingsCircle':
            return (
                <SettingsCircle width={width} height={height} className={className} {...props} />
            );
        case 'UnsplashCircle':
            return (
                <UnsplashCircle width={width} height={height} className={className} {...props} />
            );
        case 'Url':
            return <url width={width} height={height} className={className} {...props} />;
        case 'Video':
            return <Video width={width} height={height} className={className} {...props} />;

        default:
            return <div />;
    }
};

export default MarketingIcon;

MarketingIcon.propTypes = {
    name: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
};
