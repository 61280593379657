import React from 'react';

const SvgLogo = props => (
    <svg width="1em" height="1em" viewBox="0 0 60 60" {...props}>
        <defs>
            <linearGradient x1="46.851%" y1="85.476%" x2="55.078%" y2="16.671%" id="Logo_svg__a">
                <stop stopColor="#626262" offset="0%" />
                <stop offset="100%" />
            </linearGradient>
            <linearGradient x1="46.851%" y1="85.476%" x2="55.078%" y2="16.671%" id="Logo_svg__b">
                <stop stopColor="#00BAFF" offset="0%" />
                <stop stopColor="#009AD3" offset="100%" />
            </linearGradient>
            <linearGradient x1="46.851%" y1="85.476%" x2="55.078%" y2="16.671%" id="Logo_svg__c">
                <stop stopColor="#FFF" offset="0%" />
                <stop stopColor="#DCDCDC" offset="100%" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                d="M2.043 34.063l23.023-9.639c2.724-1.296 7.142-1.296 9.866 0l23.022 9.639c2.725 1.296 2.725 4.714 0 6.01L34.932 51.027c-2.724 1.296-7.142 1.296-9.866 0L2.043 40.073c-2.724-1.296-2.724-4.714 0-6.01z"
                fill="url(#Logo_svg__a)"
                style={{
                    mixBlendMode: 'multiply'
                }}
                transform="translate(0 8)"
            />
            <path
                d="M2.043 22.337l23.023-9.639c2.724-1.296 7.142-1.296 9.866 0l23.022 9.639c2.725 1.296 2.725 4.714 0 6.01L34.932 39.301c-2.724 1.296-7.142 1.296-9.866 0L2.043 28.347c-2.724-1.296-2.724-4.714 0-6.01z"
                fill="url(#Logo_svg__b)"
                transform="translate(0 8)"
            />
            <path
                d="M2.043 10.611L25.066.972c2.724-1.296 7.142-1.296 9.866 0l23.022 9.64c2.725 1.295 2.725 4.713 0 6.009L34.932 27.575c-2.724 1.297-7.142 1.297-9.866 0L2.043 16.621c-2.724-1.296-2.724-4.714 0-6.01z"
                fill="url(#Logo_svg__c)"
                opacity={0.169}
                transform="translate(0 8)"
            />
        </g>
    </svg>
);

export default SvgLogo;
