import React from 'react';

const SvgVideo = props => (
    <svg width="1em" height="1em" viewBox="0 0 60 60" {...props}>
        <g fillRule="nonzero" fill="none">
            <path
                d="M54 18H6v-2.4c0-1.988 1.79-3.6 4-3.6h40c2.21 0 4 1.612 4 3.6V18z"
                fill="#0061FF"
                opacity={0.5}
            />
            <path
                d="M48 12H12v-2c0-1.657 1.727-3 3.857-3h28.286C46.273 7 48 8.343 48 10v2z"
                fill="#0061FF"
                opacity={0.2}
            />
            <path
                d="M55.91 18H4.09C1.833 18 0 19.763 0 21.938v34.124C0 58.237 1.832 60 4.09 60h51.82c2.258 0 4.09-1.763 4.09-3.938V21.938C60 19.762 58.168 18 55.91 18z"
                fill="#0061FF"
            />
            <path
                d="M39.393 40.63L27.12 48.771a1.37 1.37 0 01-1.4.068 1.356 1.356 0 01-.72-1.197V31.357c0-.5.277-.96.72-1.196a1.37 1.37 0 011.4.067l12.273 8.143a1.355 1.355 0 010 2.258z"
                fill="#FFF"
            />
        </g>
    </svg>
);

export default SvgVideo;
