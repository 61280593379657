import React from 'react';

const SvgDropboxCircle = props => (
    <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle fill="#A2E6FF" opacity={0.2} cx={50} cy={50} r={50} />
            <path
                d="M50 40.5L40 47l10 6.5L40 60l-10-6.554 10-6.5L30 40.5 40 34l10 6.5zM40 62l10-6 10 6-10 6-10-6zm10-8.554l10.027-6.5L50 40.5l9.973-6.5L70 40.5 59.973 47 70 53.5 59.973 60 50 53.446z"
                fill="#0061FF"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default SvgDropboxCircle;
