import React from 'react';

const SvgDropbox = props => (
    <svg
        id="Dropbox_svg__Layer_1"
        data-name="Layer 1"
        viewBox="0 0 235.45 200"
        width="1em"
        height="1em"
        {...props}
    >
        <defs>
            <style>{'.Dropbox_svg__cls-1{fill:#0061ff}'}</style>
        </defs>
        <path
            className="Dropbox_svg__cls-1"
            d="M58.86 0L0 37.5 58.86 75l58.87-37.5L58.86 0zM176.59 0l-58.86 37.5L176.59 75l58.86-37.5L176.59 0zM0 112.5L58.86 150l58.87-37.5L58.86 75 0 112.5zM176.59 75l-58.86 37.5 58.86 37.5 58.86-37.5L176.59 75zM58.86 162.5l58.87 37.5 58.86-37.5-58.86-37.5-58.87 37.5z"
        />
    </svg>
);

export default SvgDropbox;
